/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

@media screen and (min-width:415px) and (max-width: 991px) {
    .mobile-view-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        height: auto;
    }
}

@media screen and (max-width: 414px) {
    .mobile-view-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 300px;
        height: auto;
    }
}

@media screen and (min-width: 992px) {
    .mobile-view-image {
        display: none;
    }
}

.cookieSettingBtn .btn-outline-primary {
    color: #0066b2;
    border-color: #0066b2 !important;
}